<template>
    <div>
      <b-modal
        id="exampleModal"
        title="Action Required"
        centered
        hide-footer
        hide-header
        no-close-on-esc
        no-close-on-backdrop
        @before-hide="preventClose"
      >
        <div class="text-center">
          <div v-if="takeControl">
            <h3>Welcome Back, Please take back control of all the employees and customers to proceed!</h3>
            <b-button variant="success" @click="handleTakeControl">Take Control</b-button>
          </div>
          <div v-else>
            <h3>You are still on Leave, Enjoy!</h3>
            <b-button variant="success" @click="logout"
            >Log Out</b-button>
          </div>
          
        </div>
      </b-modal>
      <!--  BEGIN NAVBAR  -->
      <Header></Header>
      <!--  END NAVBAR  -->
  
      <!--  BEGIN MAIN CONTAINER  -->
      <div
        class="main-container"
        id="container"
        :class="[
          !$store.state.is_show_sidebar ? 'sidebar-closed sbar-open' : '',
          $store.state.menu_style === 'collapsible-vertical'
            ? 'collapsible-vertical-mobile'
            : '',
        ]"
      >
        <!--  BEGIN OVERLAY  -->
        <div
          class="overlay"
          :class="{ show: !$store.state.is_show_sidebar }"
          @click="$store.commit('toggleSideBar', !$store.state.is_show_sidebar)"
        ></div>
        <div
          class="search-overlay"
          :class="{ show: $store.state.is_show_search }"
          @click="$store.commit('toggleSearch', !$store.state.is_show_search)"
        ></div>
        <!-- END OVERLAY -->
  
        <!--  BEGIN SIDEBAR  -->
        <Sidebar></Sidebar>
        <!--  END SIDEBAR  -->
  
        <!--  BEGIN CONTENT AREA  -->
        <div id="content" class="main-content">
          <router-view>
            <!-- BEGIN LOADER -->
            <div id="load_screen">
              <div class="loader">
                <div class="loader-content">
                  <div class="spinner-grow align-self-center"></div>
                </div>
              </div>
            </div>
            <!--  END LOADER -->
          </router-view>
  
          <!-- BEGIN FOOTER -->
          <Footer></Footer>
          <!-- END FOOTER -->
        </div>
        <!--  END CONTENT AREA  -->
  
        <!-- BEGIN APP SETTING LAUNCHER -->
        <app-settings />
        <!-- END APP SETTING LAUNCHER -->
      </div>
    </div>
</template>
<script>
  import Header from "@/components/layout/staff/header.vue";
  import Sidebar from "@/components/layout/staff/sidebar.vue";
  import Footer from "@/components/layout/staff/footer.vue";
  import appSettings from "@/components//layout/staff/id-card.vue";
  export default {
    components: {
      Header,
      Sidebar,
      Footer,
      appSettings,
    },
    data() {
      return {
        allowClose: false,
        isOnLeave: false,
        takeControl: false,
        employee: null,
      };
    },
    async mounted() {
      // Automatically open the modal on component mount
      console.log('auth -', this.$store.state.auth.userData)
      const user = this.$store.state.auth.userData
      if(user && user.uuid){
        const emp = await this.$store.dispatch('employees/getEmployeeByAuth', user.uuid);
        console.log('EMP - ', emp)
        if (emp && emp.onLeave) {
            this.employee = emp;
            this.isOnLeave = true;
            this.$bvModal.show("exampleModal");

            const leaves = await this.$store.dispatch('employees/getEmployeeLeave', emp.uuid);
            console.log('leaves- ', leaves);

            const today = new Date();
            const todayDateOnly = new Date(today.getFullYear(), today.getMonth(), today.getDate());

            // Check if any leave overlaps with today's date
            const overlappingLeave = leaves.some(leave => {
                const startDate = new Date(leave.start_date);
                const endDate = new Date(leave.end_date);

                const startDateOnly = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
                const endDateOnly = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

                // Return true if today's date is between start_date and end_date (inclusive)
                console.log(todayDateOnly >= startDateOnly && todayDateOnly <= endDateOnly);
                console.log(todayDateOnly);
                console.log(startDateOnly);
                console.log(endDateOnly);

                return todayDateOnly >= startDateOnly && todayDateOnly <= endDateOnly;
            });
            if (overlappingLeave) {
                this.takeControl = false;
            }else{
                this.takeControl = true;
            }
        }
      }
    //   console.log('EMP- ', emp)
   
      
    },
    methods: {
        async handleTakeControl(){
            const takeControl = await this.$store.dispatch('employees/takeBackControl', this.employee);
            if(takeControl){
                this.$swal.fire({
                    title: 'All the Employees & Customers Are Assigned Back to you',
                    icon: 'success',
                });
                this.allowClose = true,
                this.isOnLeave = false,
                this.$bvModal.hide("exampleModal");
            }
        },
        async logout(){
            await this.$store.dispatch('auth/logout');
            this.$router.push('/login');
        },
      preventClose(event) {
        if (!this.allowClose) {
          // Prevent the modal from closing
          event.preventDefault();
          // Optionally show a message to the user
          this.$bvToast.toast(
            "You cannot close this modal until the action is completed.",
            {
              title: "Notice",
              variant: "warning",
              solid: true,
            },
          );
        }
      },
    },
  };
</script>
  