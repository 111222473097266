const Module = () => import("./Module.vue");
const AssignEmployees = () => import("./views/AssignEmployees.vue");
const ViewShifts = () => import("./views/ViewShifts.vue");
const ViewRateCards = () => import("./views/ViewRateCards.vue");
const ViewManpowerTypes = () => import("./views/ViewManpowerTypes.vue");
import Store from '../../store'; // Adjust the path as necessary to import your store


const moduleRoute = {
  path: "/business",
  component: Module,
  children: [
    {
      path: "/",
      name: "business",
      component: AssignEmployees,
      meta: {  }
    },
    {
      path: "shifts",
      name: "shifts",
      component: ViewShifts,
      meta: {  }
    },
    {
      path: "ratecards/:id?",
      name: "ratecards",
      component: ViewRateCards,
      meta: {  }
    },
    {
      path: "designations",
      name: "designations",
      component: ViewManpowerTypes,
      meta: {  }
    },
    
  ]
};

export default router => {
  router.addRoutes([moduleRoute]);
};
